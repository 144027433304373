import React from 'react';

import { Form, Formik } from 'formik';
import tw from 'twin.macro';

import { ReactComponent as Schedule } from '../../../assets/icons/schedule.svg';
import { FlexCentredCol } from '../../../containers/ScreenContainers';
import DateInput from '../../inputs/date-input';
import TextInput from '../../inputs/text-input-with-formik';
import FormCard from '../form-card';
import { CancelButton, SubmitButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const Label = tw.label`
font-normal
text-[1.4rem]
leading-6
font-semibold
mb-[0.8rem]
`;

const ScheduleSend = ({ show, setShow, title }) => {
    const onClose = () => setShow(false);

    return (
        <div className="absolute bottom-[0.5rem] right-[0.5rem] z-[40] shadow-[0px_25px_50px_-12px_rgba(6,25,56,0.25)]">
            <FormCard headerIcon={<Schedule />} close={onClose} show={show} title={'Schedule Send'}>
                <Formik
                    initialValues={{
                        email: 'red',
                        text: '',
                        confirmtext: '',
                    }}
                    // validationSchema={ValidationSchema}
                    onSubmit={(values, actions) => {
                        
                    }}
                >
                    {({
                        errors,
                        handleChange,
                        // handleBlur,
                        isSubmitting,
                    }) => (
                        <Form>
                            <div className="gap-[3.2rem] flex flex-col">
                                <FlexCentredCol>
                                    <Label>Date</Label>
                                    <DateInput placeholder="Select Date" width="44rem" name="date" errors={errors} />
                                </FlexCentredCol>
                                <FlexCentredCol>
                                    <Label>Time</Label>
                                    <TextInput name="startTime" errors={errors} type="time" onChange={handleChange} />
                                </FlexCentredCol>
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>Cancel</CancelButton>

                                <SubmitButton type="submit">Schedule Send</SubmitButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormCard>
        </div>
    );
};

export default ScheduleSend;
