import React, { useEffect, useState } from 'react';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as BackArrow } from '../../../../../assets/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../../../assets/icons/close.svg';
import { ReactComponent as LinkIcon } from '../../../../../assets/icons/link.svg';
import { ReactComponent as Smiley } from '../../../../../assets/icons/smiley.svg';
import { Button } from '../../../../../components/buttons';
import AddNewLink from '../../../../../components/forms/add-new-link';
import ScheduleSend from '../../../../../components/forms/schedule-send';
import Select from '../../../../../components/inputs/new-select';
import { Loader } from '../../../../../components/loader';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import { getAppointmentCatgories } from '../../../../../redux/appointments/actions';
import { getLetterById, sendDraftOfficeLetter, sendOfficeLetter } from '../../../../../redux/staff-arena/actions';

const StyledButton = tw.button`
    flex
    items-center
    justify-center
    flex-row
    font-medium
    px-[1em]
    py-[.4em]
    bg-[#6366F1]
    text-[#FFF]
    text-[1.4rem]
    leading-6
    focus:outline-none
    h-[4rem]
`;
const MainContainer = tw.div`
    h-auto
    bg-white
    mx-auto
    lg:p-[2.4rem]
    md:p-[5.5rem]
    rounded-lg
`;
const Header = tw.div`
    rounded-t-lg
    border-[#E5E7EB]
    border-b
    py-[2.4rem]
    px-[3.2rem]
    bg-white
    mx-auto

`;
const SubHeader2 = tw.div`
    border-[#E5E7EB]
    border-b
    bg-white
    mx-auto
    px-[3.2rem]
    py-[1.4rem]
`;
const TextArea = tw.textarea`
    w-full
    outline-none
    placeholder:text-[#9CA3AF]
    placeholder:text-[1.4rem]
    placeholder:font-semibold
    leading-6
    font-normal
    text-[1.4rem]
    mb-5
`;
const NewInput = tw.input`
    pl-3
    rounded
    focus:outline-none
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    w-[70%]
`;

const ComposeLetter = () => {
    const navigate = useNavigate();
    const [showAddLink, setShowAddLink] = useState(false);
    const [showScheduleSend, setShowScheduleSend] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [recipientOffice, setRecipientOffice] = useState('');
    const [title, setTitle] = useState('');
    const [through, setThrough] = useState([]);
    const [senderOffice, setSenderOffice] = useState('');
    const [content, setContent] = useState('');
    const [link, setLink] = useState('');
    const [linkName, setLinkName] = useState('');
    const dispatch = useDispatch();
    const { category } = useSelector((state) => state.appointment);
    const { isLoading } = useSelector((state) => state.staffArena);
    const [isDraft, setIsDraft] = useState(false);
    const { currentDraft } = useSelector((store) => store.communications);
    const url = window.location.href;

    useEffect(() => {
        if (url.includes('draft')) {
            const fetchLetter = async () => {
                const res = await dispatch(getLetterById(currentDraft?.id));
                if (res) {
                    setTitle(res?.title);
                    setContent(res?.content);
                    setRecipientOffice(res?.recipientOffice);
                    setThrough(res?.through);
                    setSenderOffice(res?.senderOffice);
                }
            };
            fetchLetter();
        }
    }, [currentDraft?.id, dispatch, url]);

    useEffect(() => {
        dispatch(getAppointmentCatgories());
    }, [dispatch]);

    const handleAddLink = () => {
        setContent(`${content} ${linkName}`);
    };

    const onEmojiClick = (e) => {
        const sym = e.unified.split('_');
        const emojiArray = [];
        sym.forEach((el) => emojiArray.push('0x' + el));
        let emoji = String.fromCodePoint(...emojiArray);
        setContent(content + emoji);
    };

    const handleReset = () => {
        if (showPicker) {
            setShowPicker(false);
        } else if (showAddLink) {
            setShowAddLink(false);
        } else {
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = { content, link, title, recipientOffice, through, senderOffice };

        if (isDraft) {
            const res = await dispatch(sendDraftOfficeLetter(payload));
            if (res) navigate(-1);
        } else {
            const res = await dispatch(sendOfficeLetter(payload));
            if (res) navigate(-1);
        }
    };

    if (isLoading) return <Loader />;

    return (
        <div className="max-w-[1000px]" onClick={handleReset}>
            <FlexCentredRow className="mb-[3.2rem]">
                <BackArrow onClick={() => navigate(-1)} className="mr-[8px] hover:cursor-pointer" />
                <PageTitle size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Communications
                </PageTitle>
            </FlexCentredRow>

            <form onSubmit={handleSubmit}>
                <Header>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow className="gap-[1.015rem]">
                            <PageTitle>Letter</PageTitle>
                            <Dropdown />
                        </FlexCentredRow>
                        <FlexCentredRow className=" cursor-pointer gap-[6.315rem]">
                            <Close onClick={() => navigate(-1)} />
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>
                </Header>

                <SubHeader2 className="flex items-center">
                    <Text weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                        FROM (OFFICE):
                    </Text>
                    <NewInput
                        value={senderOffice}
                        onChange={(e) => setSenderOffice(e.target.value)}
                        name="from"
                        type="text"
                        placeholder="From"
                    />
                </SubHeader2>

                <SubHeader2 className="flex flex-row">
                    <Text weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                        TITLE
                    </Text>
                    <NewInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        name="subjects"
                        type="text"
                        placeholder="Title"
                        className="w-full"
                    />
                </SubHeader2>

                <SubHeader2 className="relative">
                    <div className="flex gap-[2rem] items-center">
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            THROUGH:
                        </Text>
                        <Select
                            name="through"
                            containerClass="w-[100px] md:w-[200px] lg:w-[400px] xl:w-[600px]"
                            objProp="appointmentName"
                            data={category}
                            noBorder
                            multiSelect
                            placeholder="Through"
                            onChange={(selected) => setThrough(selected?.map((item) => item.appointmentName))}
                            passedSelectedItems={through}
                        />
                    </div>
                </SubHeader2>

                <SubHeader2 className="relative">
                    <div className="flex gap-[2rem] items-center">
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TO:
                        </Text>
                        <Select
                            name="recipientOffice"
                            containerClass="w-[100px] md:w-[200px] lg:w-[400px] xl:w-[600px]"
                            objProp="appointmentName"
                            data={category}
                            noBorder
                            placeholder="Recipient"
                            onChange={(selected) => setRecipientOffice(selected[0]?.appointmentName)}
                            passedSelectedItems={recipientOffice}
                        />
                    </div>
                </SubHeader2>

                <MainContainer>
                    <TextArea
                        value={content}
                        rows="15"
                        onChange={(e) => setContent(e.target.value)}
                        placeholder="Enter Message here"
                    />

                    <FlexRowSpaceBetween className="gap-[0.2rem]">
                        <FlexCentredRow className="relative gap-[3.8rem]">
                            {showPicker && (
                                <div className=" absolute left-0  bottom-12 z-[40] shadow-[0px_25px_50px_-12px_rgba(6,25,56,0.25)]">
                                    <Picker data={data} onEmojiSelect={onEmojiClick} />
                                </div>
                            )}
                            <LinkIcon onClick={() => setShowAddLink((previousState) => !previousState)} />
                            {showAddLink ? (
                                <AddNewLink
                                    text={linkName}
                                    link={link}
                                    handleTextChange={(e) => setLinkName(e.target.value)}
                                    handleUrlChange={(e) => setLink(e.target.value)}
                                    handleAdd={handleAddLink}
                                    show={showAddLink}
                                    setShow={setShowAddLink}
                                />
                            ) : null}
                            <Smiley onClick={() => setShowPicker((val) => !val)} />
                        </FlexCentredRow>
                        <FlexCentredRow className="gap-[2.4rem]">
                            <Button border="0.5px solid #D1D5DB" onClick={() => setIsDraft(true)}>
                                Save as draft
                            </Button>
                            <FlexCentredRow>
                                <StyledButton className="py-0 rounded-l-md">Send</StyledButton>
                                <StyledButton
                                    type="button"
                                    className="py-0 border-l rounded-r-md border-l-[#A5B4FC]"
                                    onClick={() => setShowScheduleSend((previousState) => !previousState)}
                                >
                                    <Dropdown />
                                </StyledButton>
                            </FlexCentredRow>
                        </FlexCentredRow>
                        {showScheduleSend && <ScheduleSend show={showScheduleSend} setShow={setShowScheduleSend} />}
                    </FlexRowSpaceBetween>
                </MainContainer>
            </form>
        </div>
    );
};

export default ComposeLetter;
